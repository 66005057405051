<template>
  <DefaultFilter
    refFilter="CalendarMovementFilter"
    :model="filter"
    :filters="filters"
    :showRangeFilter="false"
    :showExpansion="false"
    :loading="loading"
    @search="search(filter)"
  />
</template>

<script>
import CalendarMovementMixin from '@/shared/mixins/calendarMovement/calendarMovementMixin';
import DefaultFilter from '../Default/DefaultFilter/DefaultFilter.vue';

export default {
  name: "CalendarMovementFilter",

  components: {
    DefaultFilter
  },

  data: () => ({
    filter: {},
  }),

  mixins: [
    CalendarMovementMixin,
  ],

  computed: {
    filters() {
      const inputProps = {
        dense: true,
        outlined: true,
        clearable: true,
        hideDetails: true,
        color: "primary--text",
        itemColor: "primary--text",
      };

      const comboProps = {
        itemText: "name",
        itemValue: "id",
      };

      const colsProps = {
        cols:"12",
        xl:"6",
        lg:"6",
        md:"4",
        sm:"6",
      };

      let fields = [
        {
          name: 'carrierId',
          type: 'autocomplete',
          attrs: {
            items: this.carriers,
            itemText: "xipp_commercial_name",
            itemValue: "id",
            label: 'Operadora',
            ...inputProps,
          },
          col: {
            attrs: colsProps,
          },
        },
      ];

      return fields;
    },
  },
}
</script>